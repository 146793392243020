import React, { createContext, useContext } from "react";
import { v4 } from "uuid";
import ReactDOMServer from "react-dom/server";

import { lightFormat, parseJSON, isValid, parse, toDate } from "date-fns";
import { useHistory } from "react-router";
import { farmageo_api } from "../../../config";
import axios from "axios";
import AlertasContext from "./AlertaContext";
import PantallaContext from "./PantallaContext";
import ModalesContext from "./ModalContext";
import { addConfiguracion, addsql, refrescar } from "./PantallaReducer";
import { useRefStore, useRefDataStore } from "../../..";
import { alertarSqlError } from "./componentes/Alerts";
import withReactContent from "sweetalert2-react-content";
import VistaEstatica from "../components/VistaStatica";
import {
  // @ts-ignore
  findFirstConfByTipoId,
  findFirstConfByTipoIdA,
} from "../helper/funciones";

const S = require("sweetalert2");

const FuncionesContext = createContext({});

export const guardar = async ({ valor, update_id, id_a }) => {
  if (!update_id || !id_a) {
    throw { message: "No hay update_id o id_a", id_a, update_id };
  }

  return await axios.post(farmageo_api + "/guardar", {
    valor,
    update_id,
    id_a,
  });
};

export const selector = (state, key, indiceConf, indiceData) => {
  const getConfByIndex = (c, i) => c[i];
  let configuracionState = state;
  indiceConf.forEach((i) => {
    configuracionState = getConfByIndex(configuracionState.configuraciones, i);
  });
  const dataObjeto = configuracionState.datos[indiceData];
  return dataObjeto[key];
};

export const statePath = (key, indiceConf, indiceData) => {
  let path = ""; // Inicializamos el path vacío
  indiceConf.forEach((i) => {
    path += `configuraciones.${i}.`; // Usamos el formato de acceso de objeto
  });

  return path + `datos.${indiceData}.${key}`; // Usamos el formato de acceso de objeto
};

const makemodal = (html, closeModalButtonId) => (
  <>
    <div
      className="modal fade show"
      id="staticModal"
      // @ts-ignore
      tabIndex="-1"
      role="dialog"
      aria-labelledby="staticModalLabel"
      aria-hidden="false"
      style={{
        display: "block",
        overflow: "auto",
        width: "fit-content",
        margin: "auto",
      }}
    >
      <div className="modal-dialog" role="document">
        <div className="modal-content">
          <div className="modal-header">
            <button
              type="button"
              className="close"
              data-dismiss="modal"
              aria-label="Close"
              // onClick={() => {
              //   console.log(document.getElementById("staticModal"));
              //   return document.getElementById("staticModal").remove();
              // }}
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div className="modal-body">
            <div dangerouslySetInnerHTML={{ __html: html }}></div>
          </div>
          <div className="modal-footer">
            <button
              // type="button"
              className="btn orixia-btn"
              // data-dismiss="modal"
              id={closeModalButtonId}
            >
              Cerrar
            </button>
          </div>
        </div>
      </div>
    </div>
    <div className="modal-backdrop fade show"></div>
  </>
);

/**
 * Muestra una alerta personalizada.
 *
 * @param {Object} options - Opciones para configurar la alerta.
 * @param {string} options.title - El título de la alerta.
 * @param {string} [options.text] - El texto de la alerta.
 * @param {'success' | 'error' | 'warning' | 'info' | 'question'} [options.icon] - El icono de la alerta.
 * @param {string} [options.confirmButtonText] - Texto del botón de confirmación.
 * @param {number} [options.timer=5000] - Tiempo (en milisegundos) antes de que la alerta se cierre automáticamente.
 * @param {string} [options.denyButtonText] - Texto del botón de denegación.
 * @param {string} [options.html] - HTML personalizado para mostrar en la alerta.
 * @returns {Promise<import("sweetalert2").SweetAlertResult>} Promesa que se resuelve con el resultado de la interacción del usuario.
 */
export const ALERT = ({
  title,
  text,
  icon,
  confirmButtonText,
  timer = 5000,
  denyButtonText,
  html,
}) => {
  // @ts-ignore
  return withReactContent(S).fire({
    title: title,
    text: text,
    html,
    icon: icon,
    confirmButtonText: confirmButtonText && confirmButtonText,
    timer: timer,
    denyButtonText: denyButtonText && denyButtonText,
    showDenyButton: denyButtonText && true,
    allowOutsideClick: true,
    allowEscapeKey: true,
  });
};

const alertCargando = () => {
  // @ts-ignore
  S.fire({
    title: "Cargando", // Título de la ventana emergente
    html: '<div class="spinner"></div>', // Contenido HTML con el spinner
    showCancelButton: false, // Ocultar botón de cancelar
    showConfirmButton: false, // Ocultar botón de confirmar
    allowOutsideClick: false, // No permitir hacer clic fuera de la ventana emergente
    onBeforeOpen: () => {
      // @ts-ignore
      S.showLoading(); // Mostrar el spinner
    },
    timer: 40000,
    didOpen: () => {
      // @ts-ignore
      alertCargando.id = "cargando";
    },
  });
};

export const alertarError = async (mensaje, timer, e) => {
  if (!e) return "";
  const { error } = e;

  return ALERT({
    title: "Error",
    text: mensaje,
    // @ts-ignore
    html: error ? (
      <div>
        <p>{mensaje}</p>
        {error.culpable?.map(({ id, atributo, id_a }) => (
          <a
            key={id}
            href={`/#/Vista/PANTALLA_VISTA_CONFIGURACION?id=${id}`}
            target="_blank"
            rel="noopener noreferrer"
            style={{
              textDecoration: "none",
              color: "#007bff",
              marginBottom: "5px",
              transition: "color 0.3s",
            }}
            // @ts-ignore
            onMouseEnter={(e) => (e.target.style.color = "#339af0")}
            // @ts-ignore
            onMouseLeave={(e) => (e.target.style.color = "#007bff")} // Opcional: estilos de enlace
          >
            <p style={{ margin: 0 }}>
              {id_a}: ver {atributo}
            </p>
          </a>
        ))}
      </div>
    ) : undefined,
    icon: "error",
    timer: timer ?? 10000,
    confirmButtonText: "Aceptar",
  });
};

const alertarMensaje = async (mensaje, timer = 5000) => {
  // @ts-ignore
  return await S.fire({
    position: "top-end",
    icon: "info",
    text: mensaje,
    showConfirmButton: false,
    timer,
  });
};

export const requestErrorHandler = async (res) => {
  if (!res) {
    alertarError("Error de Conexión");
  }
  if (res.status < 400) return res;

  if (res.data.error?.esSistemas) {
    alertarSqlError(res.data.error);
  } else {
    alertarError(
      res.data?.error?.message ?? "Ha ocurrido un error",
      res.data.error.timer ?? 15000,
      res.data.error
    );
  }
  return res;
};

export function requisitosRegexp(expresionRegular) {
  const condiciones = [];

  // Verificar si la expresión acepta solo letras
  if (expresionRegular.test(/^[a-zA-Z]+$/)) {
    condiciones.push("Solo se permiten letras.");
  }

  // Verificar si la expresión acepta solo mayúsculas o solo minúsculas
  if (expresionRegular.test(/^[A-Z]+$/)) {
    condiciones.push("Solo se permiten letras mayúsculas.");
  } else if (expresionRegular.test(/^[a-z]+$/)) {
    condiciones.push("Solo se permiten letras minúsculas.");
  }

  // Verificar si la expresión acepta solo números
  if (expresionRegular.test(/^\d+$/)) {
    condiciones.push("Solo se permiten números.");
  }

  // Verificar si se requiere un mínimo de caracteres
  const minimoCaracteres = expresionRegular.toString().match(/{(\d+),/);
  if (minimoCaracteres) {
    condiciones.push(
      `Se requiere un mínimo de ${minimoCaracteres[1]} caracteres.`
    );
  }

  // Verificar si hay un máximo de caracteres
  const maximoCaracteres = expresionRegular.toString().match(/{\d+,(\d+)}/);
  if (maximoCaracteres) {
    condiciones.push(
      `Se permite un máximo de ${maximoCaracteres[1]} caracteres.`
    );
  }

  // Generar el mensaje descriptivo de las condiciones
  return condiciones.length > 0
    ? `El string debe cumplir las siguientes condiciones: ${condiciones.join(
        " "
      )}`
    : "No hay condiciones específicas.";
}

export const makeRegexp = (reg) => {
  const param = reg.split("||");
  return new RegExp(param[0], param[1]);
};

export const validarRegex = (valor, validacion_regex, cab) => {
  if (!validacion_regex || validacion_regex === "") return true;
  const regexp = makeRegexp(validacion_regex);
  if (regexp.test(valor)) {
    return true;
  }
  if ([4].includes(cab?.tipo.id)) {
    alertarError(cab.helper_text);
  }

  return false;
};

/**
 * Busca una cabecera o uno de sus hijos por su id_a.
 * @param {Array<Object>} cabeceras - Array de objetos de cabeceras.
 * @param {string} r - El id_a que se está buscando.
 * @returns {Object|null} - Retorna la cabecera encontrada o null si no se encuentra.
 */

export function buscarCabeceraPorIdArray(cabeceras, r) {
  for (const cab of cabeceras) {
    if (cab.id_a === r) {
      return cab;
    }
    if (cab.sc_hijos && cab.sc_hijos.length > 0) {
      const resultado = buscarCabeceraPorId(cab.sc_hijos, r);
      if (resultado) {
        return resultado;
      }
    }
  }
  return null;
}

export function buscarCabeceraPorId(cabeceras, r) {
  if (Array.isArray(cabeceras)) {
    return buscarCabeceraPorIdArray(cabeceras, r);
  }
  if (cabeceras[r]) {
    return cabeceras[r];
  }

  for (const key in cabeceras) {
    if (cabeceras.hasOwnProperty(key)) {
      const cab = cabeceras[key];
      if (cab.sc_hijos && typeof cab.sc_hijos === "object") {
        const resultado = buscarCabeceraPorId(cab.sc_hijos, r);
        if (resultado) {
          return resultado;
        }
      }
    }
  }

  return null;
}

export const FuncionesProvider = (props) => {
  const [refState, setRefState] = useRefStore();
  // @ts-ignore
  const [refDataState, setRefDataState] = useRefDataStore();
  const { configuraciones_ref } = refState;
  const history = useHistory();
  const { ALERT, addToastAlert } = useContext(AlertasContext);
  const {
    // @ts-ignore
    PantallaDispatch,
    pantalla,
    configuraciones,
    configuraciones_cab,
    data,
  } = useContext(PantallaContext);

  const modalContext = useContext(ModalesContext);

  const pedirConfirmacion = async (props) => {
    const { cab, data } = props;

    const mensaje = (() => {
      if (data && data[cab.id_a + "_alerta_confirmar_texto"]) {
        return data[cab.id_a + "_alerta_confirmar_texto"];
      }
      if (cab.alerta_confirmar_texto) {
        return cab.alerta_confirmar_texto;
      }
      return "";
    })();

    const titulo = (() => {
      if (data && data[cab.id_a + "_alerta_confirmar_titulo"]) {
        return data[cab.id_a + "_alerta_confirmar_titulo"];
      }
      if (cab.alerta_confirmar_titulo) {
        return cab.alerta_confirmar_titulo;
      }
      return "";
    })();

    return ALERT({
      title: titulo,
      text: mensaje,
      icon: "question",
      denyButtonText: "Cancelar",
      confirmButtonText: "Confirmar",
    });
  };

  const alertarExito = async (res, cab, data) => {
    let { alerta_titulo, alerta_texto } = cab;

    if (data) {
      const alerta_titulo_alias = cab.id_a + "_alerta_titulo";
      const alerta_texto_alias = cab.id_a + "_alerta_texto";
      if (data[alerta_texto_alias]) {
        alerta_texto = data[alerta_texto_alias];
      }
      if (data[alerta_titulo_alias]) {
        alerta_titulo = data[alerta_titulo_alias];
      }
    }

    return ALERT({
      title: alerta_titulo ?? "Exito",
      text:
        res.data.msg ??
        alerta_texto ??
        "La operación se ha completado con éxito.",
      icon: "success",
      confirmButtonText: "Aceptar",
    });
  };

  const superSubmit = async ({
    valor,
    id_a,
    update_id,
    handleCancelar,
    cab,
    data,
    indiceData,
  }) => {
    document.body.style.cursor = "progress";
    if (!update_id || update_id === "") {
      if (cab.alerta_confirmar === "s") {
        return await insertarConConfirmacion({
          valor,
          id_a,
          update_id,
          handleCancelar,
          cab,
          data,
          indiceData,
        });
      }

      return await insertarSinConfirmar({
        valor,
        id_a,
        update_id,
        handleCancelar,
        cab,
        data,
        indiceData,
      });
    }

    if (cab.alerta_confirmar === "s") {
      return await guardarConConfirmacion({
        valor,
        id_a,
        update_id,
        handleCancelar,
        cab,
        data,
        indiceData,
      });
    }
    return await guardarSinConfirmar({
      valor,
      id_a,
      update_id,
      handleCancelar,
      cab,
      data,
      indiceData,
    });
  };

  const guardarSinConfirmar = async (props) => {
    const { valor, id_a, update_id, handleCancelar, cab, data, indiceData } =
      props;
    let toastNotification; // Notificacion Toast
    const stringInform = cab.nombre ?? cab.label ?? cab.campo;
    if (
      data[cab.id_a] === null &&
      typeof valor === "string" &&
      valor.trim() === ""
    ) {
      return handleCancelar();
    }
    if (cab.update_bloquear === "s") {
      alertCargando();
    } else {
      toastNotification = addToastAlert({
        message: `Guardando ${stringInform} ...`,
        type: "procesando",
      }); // Agrega notificacion toast
      toastNotification.show(); // Manipula la notificacion
    }
    return guardar({
      valor,
      id_a,
      update_id,
      // @ts-ignore
      handleCancelar,
      cab,
      data,
      indiceData,
    })
      .then((res) => {
        if (cab.update_bloquear === "s") {
          // @ts-ignore
          S.close();
        }
        return handleResponse({
          response: res,
          cab,
          data,
          toastNotification,
          stringInform,
        });
      })
      .catch((err) => {
        if (cab.update_bloquear === "s") {
          // @ts-ignore
          S.close();
        } else {
          toastNotification.toError({ message: err });
        }
        handleCancelar();
        requestErrorHandler(err);
        throw err;
      });
  };

  const guardarConConfirmacion = async (props) => {
    const { data, cab } = props;

    return pedirConfirmacion({ data, cab }).then(async (result) => {
      if (!result.isConfirmed) {
        props.handleCancelar();
        throw result;
      }
      return await guardarSinConfirmar(props);
    });
  };

  const insertarSinConfirmar = async (props) => {
    const { valor, id_a, update_id, handleCancelar, cab, data, indiceData } =
      props;

    let toastNotification; // Notificacion Toast
    const stringInform = cab.nombre ?? cab.label ?? cab.campo;

    if (
      data[cab.id_a] === null &&
      typeof valor === "string" &&
      valor.trim() === ""
    ) {
      return handleCancelar();
    }
    if (cab.update_bloquear === "s") {
      alertCargando();
    } else {
      toastNotification = addToastAlert({
        message: `Guardando ${stringInform} ...`,
        type: "procesando",
      }); // Agrega notificacion toast
      toastNotification.show(); // Manipula la notificacion
    }

    let insertIds = data[cab.insert_ids_alias];

    return await insertar({
      valor,
      id_a,
      // @ts-ignore
      update_id,
      handleCancelar,
      cab,
      data,
      indiceData,
      insert_ids: insertIds ?? cab.insert_ids,
    })
      .then((res) => {
        if (cab.update_bloquear === "s") {
          // @ts-ignore
          S.close();
        }
        return handleResponse({
          response: res,
          cab,
          data,
          toastNotification,
          stringInform,
        });
      })
      .catch((err) => {
        if (cab.update_bloquear === "s") {
          // @ts-ignore
          S.close();
        } else {
          toastNotification.toError({ message: err });
        }
        handleCancelar();
        requestErrorHandler(err);
        throw err;
      });
  };

  const insertarConConfirmacion = async (props) => {
    const { data, cab } = props;

    return pedirConfirmacion({ data, cab }).then(async (result) => {
      if (!result.isConfirmed) {
        props.handleCancelar();
        throw result;
      }
      return await insertarSinConfirmar(props);
    });
  };

  const guardar = async ({ valor, update_id, id_a }) => {
    if (!update_id || !id_a) {
      throw { message: "No hay update_id o id_a", id_a, update_id };
    }

    return await axios.post(farmageo_api + "/guardar", {
      valor,
      update_id,
      id_a,
    });
  };

  // @ts-ignore
  const guardarEP = async ({ data, cab, indiceData, handleCancelar }) => {
    const { id_a, update_id_alias } = cab;
    const update_id = data[update_id_alias];

    if (!update_id || !id_a) {
      throw { message: "No hay update_id o id_a", id_a, update_id };
    }

    try {
      axios
        .post(farmageo_api + "/guardar", {
          update_id,
          id_a,
        })
        .then((res) => {
          if (res.status >= 400) {
            requestErrorHandler(res);
            throw res.data;
          }
          if (cab.alerta_exito === "s") {
            alertarExito(res, cab, data);
          }
          if (
            cab.refrescarConfiguracion &&
            cab.refrescarConfiguracion.trim() !== ""
          ) {
            refrescarConfiguracion({ cab });
          }
          return res;
        });
    } catch (err) {
      handleCancelar();
      requestErrorHandler(err);
      throw err;
    }
  };

  const insertar = async ({ valor, id_a, insert_ids }) => {
    return await axios.post(farmageo_api + "/insertar", {
      valor,
      id_a,
      insert_ids,
    });
  };

  const subirArchivo = async ({
    archivo,
    valor,
    handleCancelar,
    cab,
    data,
    // @ts-ignore
    indiceData,
  }) => {
    const formData = new FormData();
    formData.append("archivo", archivo);
    formData.append("valor", valor);
    formData.append("insert_ids", data[cab.insert_ids_alias]);
    formData.append("update_id", data[cab.update_id_alias]);
    formData.append("id_a", cab.id_a);

    if (cab.alerta_confirmar === "s") {
      const confirmacion = await pedirConfirmacion();
      if (!confirmacion.isConfirmed) {
        handleCancelar();
      }
    }
    const ruta = !data[cab.update_id_alias] ? "/insertar" : "/guardar";

    return await axios
      .post(farmageo_api + ruta, formData)
      .then((res) => {
        // @ts-ignore
        return handleResponse({ response: res, cab, data });
      })
      .catch((err) => {
        handleCancelar();
        requestErrorHandler(err);
        throw err;
      });
  };

  const eliminar = async ({ id_a, delete_id }) => {
    return await axios.post(farmageo_api + "/eliminar", {
      id_a,
      delete_id,
    });
  };

  const refrescarConfiguracion = async ({ cab }) => {
    if (!cab.refrescarConfiguracion)
      throw new Error("No hay cab.refrescarConfiguracion");
    try {
      const ids = cab.refrescarConfiguracion.split(",");
      ids.forEach(async (id) => {
        const event = new CustomEvent(id.trim(), { detail: "refrescar" });
        document.dispatchEvent(event);

        if (!configuraciones_ref[id]) return;

        await setRefState({ configuraciones_ref: refrescar(refState, id) });
      });
      return "sin problemas";
    } catch (er) {
      console.log(er);
      return er;
    }
  };

  const getConfiguracion = async (id_a, qsBody, params = {}, pantalla_id) => {
    params.pantalla = id_a;
    params.conf_padre = pantalla_id;

    return axios
      .post(
        farmageo_api + "/config/" + pantalla,
        Object.assign(qsBody, params),
        { params }
      )
      .then((res) => {
        //console.log(res);
        return res;
      });
  };

  const getPantalla = async (id_a, id, params, qsBody) => {
    params.pantalla = id_a;
    return axios
      .post(
        farmageo_api + "/pantalla/" + id_a,
        Object.assign({ id }, qsBody, params),
        {
          params,
        }
      )
      .then((res) => {
        return res;
      });
  };

  const checkID_A = (string) => {
    const regex = new RegExp("(^[A-Z]+(_[A-Z]+)*)$", "g");

    const esID_A = regex.test(string);
    return esID_A;
  };

  const ABMSubmit = async ({ opciones, id_a, qsBody, params, setLoading }) => {
    const { endpoint, alerta_confirmar } = opciones;
    try {
      let confirmado = true;

      if (alerta_confirmar)
        confirmado = (await pedirConfirmacion({ cab: opciones })).isConfirmed;

      if (!confirmado) throw { code: "cancelado", status: 200 };

      setLoading(true);

      const res = await putConfiguracion(id_a, qsBody, params, endpoint);
      document.body.style.cursor = "progress";
      setLoading(false);
      if (res.status > 400) {
        throw res;
      }
      if (res.status < 400) {
        await manejarAbmRespuesta.despachar(opciones, res);
      }
      // @ts-ignore
      return handleResponse({
        response: res,
        cab: opciones,
        // @ts-ignore
        data: res.registro, // deberia ser la data de la conf
      });
    } catch (res) {
      console.log("error abm submit", res);
      document.body.style.cursor = "default";
      setRefState({ sql: addsql(res.data?.sql, refState.sql) });

      if (res.code === "cancelado") return res;
      alertarError(res.data?.error.message);
      return res;
    }
  };

  const FormularioSubmit = async ({
    opciones,
    id_a,
    qsBody,
    params,
    setLoading,
  }) => {
    const { endpoint, alerta_confirmar } = opciones;
    try {
      let confirmado = true;

      if (alerta_confirmar)
        confirmado = (await pedirConfirmacion({ cab: opciones })).isConfirmed;

      if (!confirmado) throw { code: "cancelado", status: 200 };

      setLoading(true);
      document.body.style.cursor = "progress";
      const res = await putConfiguracion(
        id_a,
        qsBody,
        params,
        endpoint ?? "/formulario"
      );

      setLoading(false);
      if (res.status > 400) {
        throw res;
      }
      if (res.status < 400) {
        await manejarAbmRespuesta.despachar(opciones, res);
      }
      // @ts-ignore
      return handleResponse({
        response: res,
        cab: opciones,
        // @ts-ignore
        data: res.registro, // deberia ser la data de la conf
      });
    } catch (res) {
      console.log("error abm submit", res);
      document.body.style.cursor = "default";

      setRefState({ sql: addsql(res.data?.sql, refState.sql) });

      if (res.code === "cancelado") return res;
      alertarError(res.data?.error.message);
      return res;
    }
  };

  class manejarAbmRespuesta {
    constructor(opciones, respuesta) {
      this.res = respuesta;
      this.opciones = opciones;
    }

    async despachoModal() {
      // @ts-ignore
      const { enlace_siguiente, alerta_exito } = this.opciones;

      // if (alerta_exito) await alertarExito(this.res, this.opciones);

      if (enlace_siguiente)
        // @ts-ignore
        return redireccionar({ cab: this.opciones, res: this.res });

      if (
        this.opciones.refrescarConfiguracion &&
        this.opciones.refrescarConfiguracion.trim() !== ""
      ) {
        return refrescarConfiguracion({ cab: this.opciones });
      }
    }

    async despacho() {
      const { enlace_siguiente, alerta_exito, funcion_onResponse } =
        this.opciones;

      if (alerta_exito) await alertarExito(this.res, this.opciones);

      if (enlace_siguiente)
        // @ts-ignore
        return redireccionar({ cab: this.opciones, res: this.res });
      if (
        this.opciones.refrescarConfiguracion &&
        this.opciones.refrescarConfiguracion.trim() !== ""
      ) {
        refrescarConfiguracion({ cab: this.opciones });
      }

      if (funcion_onResponse) {
        const funcion = funcion_onResponse.split("|")[0];

        // eslint-disable-next-line no-eval
        eval(
          f[funcion]({
            response: this.res,
            opciones: this.opciones,
          })
        );
      }
    }

    static async despachar(opciones, respuesta) {
      const abmRes = new manejarAbmRespuesta(opciones, respuesta);

      if (opciones.esModal) {
        return await abmRes.despachoModal();
      }
      return await abmRes.despacho();
    }
  }

  // @ts-ignore
  const redireccionar = async ({ cab, data, res }) => {
    const enlace_siguiente_pasar_id = cab.enlace_siguiente_pasar_id === "s";
    const id_nombre = cab.update_id_nombre ?? "id";

    // console.log(data, cab, res);
    let search = enlace_siguiente_pasar_id
      ? res.data.registro
        ? `?&id=${res.data.registro[id_nombre]}`
        : undefined
      : undefined;

    if (res.data.usarParams) {
      search = "";
      Object.keys(res.data.usarParams).forEach((k) => {
        search = search.concat(`?&${k}=${res.data.usarParams[k]}`);
      });
    }
    // console.log("redireccionar", res);

    if (checkID_A(cab.enlace_siguiente.split("?")[0])) {
      const location = {
        pathname: `/Configuracion/${cab.enlace_siguiente}`,
        search,
      };

      history.push(location);
      return window.location.reload();
    }

    return history.push({
      pathname: `${cab.enlace_siguiente}`,
    });
  };

  const putConfiguracion = async (
    id_a,
    qsBody,
    formData,
    endpoint = "/insertar"
  ) => {
    //body.id_a = id_a;
    // body.id = qsBody?.id;
    formData.set("id_a", id_a);
    if (qsBody?.id && !formData.id) {
      // console.log("hello append");
      // formData.append("id", qsBody?.id);
    }
    return await axios.post(farmageo_api + endpoint, formData);
  };

  const eliminarRegistro = async (props) => {
    // @ts-ignore
    const { data, cab, indiceData, callback } = props;

    cab.alerta_confirmar_texto =
      cab.alerta_confirmar_texto ?? "Esta acción no se puede deshacer.";

    const result = await pedirConfirmacion({ data, cab });

    if (!result.isConfirmed) {
      props.handleCancelar();
      throw result;
    }
    try {
      const response = await eliminar({
        id_a: cab.id_a,
        delete_id: data[cab.delete_id_alias],
      });
      callback(response);
      // @ts-ignore
      return handleResponse({ response, cab, data });
    } catch (err) {
      callback(err);
      ALERT({
        title: "Error",
        text: err.message,
        icon: "error",
        confirmButtonText: "Aceptar",
      });
      return err;
    }
  };

  // @ts-ignore
  const setAtributo = ({ data, cab, indiceData, handleCancelar, dispatch }) => {
    // eslint-disable-next-line no-unused-expressions
    cab.efecto_objetivo?.split(",").forEach((id_a) => {
      const efecto_atributo = cab.efecto_atributo?.split(",");

      if (!efecto_atributo || efecto_atributo.length !== 2) return;

      dispatch({
        type: "SET_DATO_ESPECIFICO",
        payload: {
          key: id_a + "_" + efecto_atributo[0],
          indiceData: indiceData,
          value: efecto_atributo[1],
        },
      });

      // const el = document.getElementById(id_a + indiceData);
      // el.disabled = true;
      // el.classList.add("input-disabled");
    });
  };

  function transformarTipoDato(valor, tipoObjetivo) {
    switch (tipoObjetivo) {
      case "string":
        valor = String(valor);
        break;
      case "number":
        valor = Number(valor);
        break;
      case "boolean":
        valor = Boolean(valor);
        break;
      // Agrega más casos según tus necesidades
      default:
        break;
    }
    return valor;
  }

  const enviarAModal = async (id_a, data, propsModal, qsBody) => {
    try {
      const conf = await getPantalla(id_a, data.id, data, qsBody);

      const {
        configuraciones: nuevasConfiguraciones,
        configuraciones_ref,
        configuraciones_ida,
        configuraciones_ids,
        configuraciones_tipo,
        configuraciones_padre,
        configuraciones_cab,
        configuracionesDatos,
      } = addConfiguracion(conf.data, refState);

      setRefState({
        configuraciones: nuevasConfiguraciones,
        configuraciones_ref,
        configuraciones_ida,
        configuraciones_ids,
        configuraciones_tipo,
        configuraciones_padre,
        configuraciones_cab,
      });

      setRefDataState(configuracionesDatos);
      // hay un problema de tiempo de ejecucion. Si setRefState va despues de setRefDataState,
      // no alcanza a actualizarse el estado cuando se abre el modal
      const { addModal } = modalContext;
      conf.data.opciones.modal = true;
      addModal({
        id_a,
        data,
        parametro_id: data.id,
        idx: configuraciones.length,
        propsModal,
        qsBody,
      });
    } catch (err) {
      console.log(err);
      return;
    }
  };

  // @ts-ignore
  function nada(p) {
    console.log("funcion que no hace nada: ");
  }

  async function webService(p) {
    function cadenaAObjeto(cadena) {
      const pares = cadena.split("|");
      const objeto = {};

      pares.forEach((par) => {
        const [clave, valor] = par.split("=");
        objeto[clave] = valor;
      });

      return objeto;
    }
    const { cab, data } = p;
    const endpoint = data[cab.id_a + "_endpoint"] ?? cab.endpoint;

    try {
      if (data.length === 0)
        // eslint-disable-next-line no-throw-literal
        throw {
          code: "NO HAY DATA SELECCIONADA",
          msg: "No esta enviando ningun dato.",
        };

      if (cab.alerta_confirmar === "s") {
        const confirmacion = await pedirConfirmacion({ cab, data });

        if (!confirmacion.isConfirmed) {
          return p.handleCancelar();
        }
      }

      axios
        .post(
          farmageo_api + endpoint,
          cadenaAObjeto(data[cab.id_a + "_webService_body"])
        )
        .then((response) => {
          // @ts-ignore
          return handleResponse({ response, cab, data });
        });
    } catch (err) {
      console.log(err);
      ALERT({
        text: err.msg,
        icon: "error",
        confirmButtonText: "Aceptar",
      });
    }
  }

  const processListado = (listado, pantallaDatos = refDataState) => {
    pantallaDatos = pantallaDatos ?? refDataState;
    return listado.datos.map((d) => {
      let listadoViewElement = {};
      listado.cabeceras.forEach((c) => {
        const nombreKey =
          c.id_a +
          (["autocompletar", "enum", "select"].includes(c.componente)
            ? "_label"
            : "");

        const value = getLabelValue(
          c.id_a,
          c.componente ?? "columna_simple",
          c.opciones,
          nombreKey,
          d[c.id_a],
          d,
          pantallaDatos
        );
        if (c.clave_envio && c.componente !== "div") {
          listadoViewElement[c.clave_envio] = value;
        }

        if (c.componente === "div") {
          listadoViewElement = {
            ...listadoViewElement,
            ...buildBodyToSend(c, d),
          };
        }
      });
      return listadoViewElement;
    });
  };

  // @ts-ignore
  const getLabelValue = (
    id_a,
    componente,
    opciones,
    key,
    value,
    dataRow,
    data
  ) => {
    try {
      const { _key } = dataRow;
      if (
        componente.includes("autocompletar") ||
        componente === "enum" ||
        componente === "select"
      ) {
        return opciones
          .filter((op) => op.value.toString() === value?.toString())
          .pop()?.label;
      }
      const updatedValue =
        data[
          Object.keys(data)
            .filter((k) => k.endsWith(_key + id_a))
            .pop()
        ];
      return updatedValue;
    } catch (err) {
      console.log("getLabelValue", err);
      throw err;
    }
  };

  const setDataView = () => {
    const dataView = {};

    Object.keys(configuraciones_cab).forEach((key) => {
      const cab = configuraciones_cab[key];

      // Procesar sc_hijos
      if (cab.sc_hijos?.length > 0) {
        cab.sc_hijos.forEach((child) => {
          if (child.clave_envio) {
            Object.keys(data).forEach((k) => {
              if (k.endsWith(child.id_a)) {
                dataView[child.clave_envio] = data[k];
              }
            });
          }
        });
      }

      // Procesar clave_envio directa
      if (cab.clave_envio) {
        const nombreKey =
          cab.id_a +
          (["autocompletar", "enum", "select"].includes(cab.componente)
            ? "_label"
            : "");
        Object.keys(data).forEach((k) => {
          if (k.endsWith(nombreKey)) {
            dataView[cab.clave_envio] = data[k];
          }
        });
      }

      // Procesar opciones
      if (cab.opciones?.clave_envio) {
        dataView[cab.opciones.clave_envio] = processListado(cab);
      }
    });

    // Procesar configuraciones con tipo.id === 2
    configuraciones
      .filter((c) => c.opciones.tipo.id === 2)
      .forEach((listado) => {
        dataView[listado.opciones.clave_envio] = processListado(listado);
      });

    return dataView;
  };

  const buildBodyToSend = (conf, data) => {
    try {
      const filteredBody = [];

      // Filtrar elementos relevantes
      if (conf.sc_hijos) {
        filteredBody.push(
          ...conf.sc_hijos.filter((sc) => [4, 2, 6].includes(sc.tipo.id))
        );
      }
      if (conf.cabeceras) {
        filteredBody.push(
          ...conf.cabeceras.filter((sc) => [4, 2, 6].includes(sc.tipo.id))
        );
      }

      const _body = {};

      // Procesar elementos filtrados
      for (const fb of filteredBody) {
        const clave = fb.opciones?.clave_envio || fb.clave_envio;

        // sacar valor

        if (clave) {
          if (fb.tipo.id === 4) {
            const nombreKey =
              fb.id_a +
              ([
                "autocompletar",
                "enum",
                "select",
                "autocompletar_fetch",
              ].includes(fb.componente)
                ? "_label"
                : "");

            if ("grupo_columnas" === fb.componente) {
              _body[clave] = buildBodyToSend(fb, data);
            } else if ("div" === fb.componente) {
              console.log("div", fb);
              _body[clave] = buildBodyToSend(fb, data);
            } else if ("ver_archivo" === fb.componente) {
              _body[clave] = data[fb.enlace_alias];
            } else {
              for (const k of Object.keys(data)) {
                if (k.endsWith(nombreKey)) {
                  _body[clave] = data[k];
                }
              }
            }
          }
          if (fb.tipo.id === 2) {
            const g = findFirstConfByTipoIdA({ configuraciones }, fb.id_a);
            //console.log(g, fb.id_a)
            _body[clave] = processListado(g);
          }
          // aca va data [key]
        } else if (fb.sc_hijos || fb.cabeceras) {
          // Combinar resultados anidados si no hay clave
          Object.assign(
            _body,
            buildBodyToSend(
              {
                sc_hijos: fb.sc_hijos,
                cabeceras: fb.cabeceras,
              },
              data
            )
          );
        }
      }
      return _body;
    } catch (err) {
      console.error("build body to send error: ", err);
      throw err; // Devuelve el cuerpo original si hay un error
    }
  };

  const processConsulta = (consulta, dataviewlabel) => {
    dataviewlabel = consulta.clave_envio;

    const desiredStructure = buildBodyToSend(consulta, data);

    return desiredStructure;
  };

  async function endpoint(p) {
    const { cab, data, sideData, context, callback } = p;
    const endpoint = data[cab.id_a + "_endpoint"] ?? cab.endpoint;

    try {
      if (data.length === 0)
        // eslint-disable-next-line no-throw-literal
        throw {
          code: "NO HAY DATA SELECCIONADA",
          msg: "No esta enviando ningun dato.",
        };

      if (cab.alerta_confirmar === "s") {
        const confirmacion = await pedirConfirmacion({ cab, data });

        if (!confirmacion.isConfirmed) {
          return p.handleCancelar();
        }
      }
      document.body.style.cursor = "progress";

      // const consulta = findFirstConfByTipoId(cab, 23);

      const consultas = cab.sc_hijos.filter((c) => c.tipo?.id === 23);

      let dataviewcontent = {}; // processConsulta(consulta);
      let ejecutarRequest = true;
      if (consultas.length > 0) {
        consultas.forEach((c) => {
          return (dataviewcontent = Object.assign(dataviewcontent, {
            [c.clave_envio]: processConsulta(c),
          }));
        });
      }

      if (cab.tipo.id === 8) {
        // solo para los listadoBoton porque se valida un array de elementos y requiere confirmacion cada uno
        // aca aplico http de validacion

        // Actualizar datos_seleccionados

        await axios
          .post(farmageo_api + "/listado/validarListadoSelecionado", {
            data,
            sideData,
            datosSeleccionados: context?.datos_seleccionados,
            id: context?.id,
            filtros: context?.filtroActivo,
            confs: {
              ejecutor: cab.id_a,
              pantalla: context?.pantalla,
              conf: context?.opciones?.id_a,
            },
            ...dataviewcontent,
          })
          .then(async (response) => {
            let necesitaConfirmacion = response.data.necesitaConfirmacion;
            // let validacionFallo = response.data.validacionFallo;
            callback();
            if (response.status >= 400) {
              ejecutarRequest = false;
              handleResponse({ response, cab, data });
            }
            if (necesitaConfirmacion) {
              // VALIDACION INDIVIDUAL POR ELEMENTO
              //   refState.configuraciones
              //     .filter((c) => c?.opciones?.id_a === cab.padre)
              //     .pop()
              // );
              // const alertRes = await ALERT({
              //   title: "Confirme las Ordenes de Compra",
              //   text: "Algunas Ordenes de compra superan el limite establecido.",
              //   icon: "question",
              //   html: (
              //     <>
              //       <div>ava vamos a poner el listado {cab.padre}</div>
              //     </>
              //   ),
              //   denyButtonText: "Cancelar",
              //   confirmButtonText: "Confirmar",
              // });

              // APROBACION GENERAL

              const usuarioResponse = await ALERT({
                title: "La Accion requiere confirmacion.",
                text: response.data.message,
                icon: "question",
                denyButtonText: "Cancelar",
                confirmButtonText: "Continuar de todas formas",
              });
              //context.datos_seleccionados = elementosSupervisados;
              if (!usuarioResponse.isConfirmed) {
                ejecutarRequest = false;
              }
            }
          })
          .catch((err) => {
            console.log("esto es un error", err);
          });
      }
      // @ts-ignore
      // console.log("REQUESTENDPOINT");
      if (ejecutarRequest) {
        return requestEndpoint({
          sideData,
          context,
          dataviewcontent,
          cab,
          data,
          endpoint,
        });
      } else {
        document.body.style.cursor = "default";
      }
    } catch (err) {
      console.log(err);
      ALERT({
        text: err.msg,
        icon: "error",
        confirmButtonText: "Aceptar",
      });
    }
  }

  async function requestEndpoint({
    sideData,
    context,
    dataviewcontent,
    cab,
    data,
    endpoint,
  }) {
    return await axios
      .post(farmageo_api + endpoint, {
        data,
        sideData,
        datosSeleccionados: context?.datos_seleccionados, // tal vez convenga enviarlos de esta manera
        id: context?.id,
        filtros: context?.filtroActivo,
        confs: {
          ejecutor: cab.id_a,
          pantalla: context?.pantalla,
          conf: context?.opciones?.id_a,
        },
        ...dataviewcontent,
      })
      .then((response) => {
        // @ts-ignore
        return handleResponse({ response, cab, data });
      })
      .catch((response) => {
        return handleResponse({ response, cab, data });
      });
  }

  async function endpointToHtml(p) {
    const { cab, data } = p;
    try {
      if (cab.alerta_confirmar === "s") {
        const confirmacion = await pedirConfirmacion({ cab, data });

        if (!confirmacion.isConfirmed) {
          return p.handleCancelar();
        }
      }

      axios.post(farmageo_api + cab.endpoint, data).then((response) => {
        // @ts-ignore
        handleResponse({ response, cab, data });

        const div = document.createElement("div");
        div.innerHTML = response.data.toString();

        const el = document.getElementById(cab.endpoint_insertarHTML_en);

        el.appendChild(div);
      });
    } catch (err) {
      console.log(err);
      ALERT({
        text: err.msg,
        icon: "error",
        confirmButtonText: "Aceptar",
      });
    }
  }

  const createModalRoot = () => {
    const modalRoot = document.createElement("div");
    modalRoot.id = "modal-bootstrap-root";
    document.body.appendChild(modalRoot);
  };

  function injectModal(html) {
    let modalRoot = document.getElementById("modal-bootstrap-root");
    if (!modalRoot) {
      createModalRoot();
      modalRoot = document.getElementById("modal-bootstrap-root");
    }

    // Inyecta el HTML del modal en el modalRoot
    modalRoot.innerHTML = html;

    // Agrega event listener para cerrar el modal
    const closeButton = modalRoot.querySelector(".close");
    const backdropElement = modalRoot.querySelector(".modal-backdrop");
    function removeModal() {
      modalRoot.innerHTML = ""; // Limpia el contenido del modal
      // Elimina el modalRoot si ya no hay modales
      if (!modalRoot.querySelector(".modal")) {
        modalRoot.remove();
      }
    }
    if (closeButton) {
      closeButton.addEventListener("click", () => {
        modalRoot.innerHTML = ""; // Limpia el contenido del modal
      });
    }

    if (backdropElement) {
      backdropElement.addEventListener("click", removeModal);
    }
  }

  const renderEnModal = (html) => {
    const closeModalButtonId = v4();
    const modalhtml = ReactDOMServer.renderToStaticMarkup(
      makemodal(html, closeModalButtonId)
    );

    injectModal(modalhtml);
    document
      .getElementById(closeModalButtonId)
      .addEventListener("click", () => {
        const modal = document.getElementById("staticModal");
        const backdrop = document.querySelector(".modal-backdrop");
        if (modal) modal.remove();
        if (backdrop) backdrop.remove();
      });
  };

  const handleResponse = async ({
    response,
    cab,
    data,
    toastNotification,
    stringInform,
  }) => {
    // console.log("THIS IS RESPONSE", response);
    if (
      response.headers &&
      response.headers["x-display-nueva-ventana"] === "s"
    ) {
      if (response.headers["content-type"] === "application/pdf") {
        const { blob, fileName } = await descargarArchivo(response.data.pdf);
        manejarArchivoDescargado(blob, fileName, "abrir", response);
      }
      if (response.headers["content-type"] === "text/html") {
        manejarArchivoDescargado(response.data.html, "html", "abrir", response);
      }
    }
    document.body.style.cursor = "default";
    setRefState({ sql: addsql(response.data?.sql, refState.sql) });
    if (response.status >= 400) {
      toastNotification &&
        toastNotification.toError({
          message: response.data?.error?.message ?? "Ha ocurrido un error",
        });
      return requestErrorHandler(response);
    }
    toastNotification &&
      toastNotification.toSuccess({
        message: `${stringInform} ha sido guardado exitosamente`,
      });
    if (cab.alerta_exito === "s") {
      alertarExito(response, cab, data);
    }
    if (response.data.message && response.data.message !== "") {
      alertarMensaje(response.data.message, response.data.timer);
    }
    if (cab.funcion_onResponse && cab.funcion_onResponse.trim() !== "") {
      f[cab.funcion_onResponse.split("|")[0]]({ response, opciones: cab });
    }

    if (
      cab.refrescarConfiguracion &&
      cab.refrescarConfiguracion.trim() !== ""
    ) {
      refrescarConfiguracion({ cab });
    }

    if (
      response.data.html &&
      response.headers["x-display-nueva-ventana"] !== "s"
    ) {
      renderEnModal(response.data.html);
    }
    return response;
  };

  const descargar = (ruta) => {
    axios
      .post(ruta, {}, { responseType: "blob" })
      .then((response) => {
        const blob = new Blob([response.data], {
          type: response.headers["content-type"],
        });
        const fileName = response.headers["filename"];

        const reader = new FileReader();
        // Leer el blob como un ArrayBuffer usando FileReader

        reader.onload = () => {
          if (response.status >= 400) {
            const decoder = new TextDecoder();
            // @ts-ignore
            const decodedData = decoder.decode(reader.result);
            const parsedData = JSON.parse(decodedData);
            // Pasa la respuesta parseada al manejador de errores
            return requestErrorHandler({
              ...response,
              data: parsedData,
            });
          }
          // Crear un blob a partir de los datos leídos por el FileReader
          const blobUrl = window.URL.createObjectURL(
            new Blob([reader.result], {
              type: response.headers["content-type"],
            })
          );

          // Crear un enlace para descargar el archivo
          const link = document.createElement("a");
          link.href = blobUrl;
          link.setAttribute("download", fileName);
          document.body.appendChild(link);

          // Simular un clic en el enlace para iniciar la descarga
          link.click();

          // Eliminar el enlace del DOM después de la descarga
          document.body.removeChild(link);
        };
        reader.readAsArrayBuffer(blob);
      })
      .catch((error) => {
        console.error("Error al descargar el archivo:", error);
      });
  };

  function abrirModalEstatico({
    data,
    cab,
    // @ts-ignore
    handleCancelar,
    // @ts-ignore
    sideData,
    // @ts-ignore
    context,
  }) {
    const innerhtml = ReactDOMServer.renderToStaticMarkup(
      // @ts-ignore
      <VistaEstatica data={data} cab={cab} />
    );
    renderEnModal(innerhtml);
  }

  const insertarEnHtml = ({ response, opciones }) => {
    const { funcion_onResponse } = opciones;
    const params = funcion_onResponse.split("|");
    const divId = params[1];
    const dataPath = params[2];
    const div = document.getElementById(divId);
    //console.log(params, response, divId, div)
    div.innerHTML = response.data[dataPath];
    return response;
  };
  const f = {
    setDataView,
    insertarEnHtml,
    abrirModalEstatico,
    insertar,
    insertarConConfirmacion,
    insertarSinConfirmar,
    guardarEP,
    guardar,
    guardarSinConfirmar,
    guardarConConfirmacion,
    refrescarConfiguracion,
    getConfiguracion,
    putConfiguracion,
    eliminarRegistro,
    requestErrorHandler,
    superSubmit,
    ABMSubmit,
    FormularioSubmit,
    subirArchivo,
    checkID_A,
    enviarAModal,
    nada,
    endpoint,
    endpointToHtml,
    webService,
    setAtributo,
    transformarTipoDato,
    requisitosRegexp,
    validarRegex,
    descargar,
    selector,
    statePath,
    processListado,
  };
  return (
    <FuncionesContext.Provider value={f}>
      {props.children}
    </FuncionesContext.Provider>
  );
};

export default FuncionesContext;

export function parseQueryString(queryString) {
  if (!queryString) return {};
  const params = {};
  const pairs = queryString.substring(1).split("&");

  for (const pair of pairs) {
    const [key, value] = pair.split("=");
    params[key] = value;
  }

  return params;
}

export const fecha = (data, cab, campokey) => {
  try {
    return lightFormat(
      parseJSON(data[campokey]),
      cab["fecha_formato"] ? cab["fecha_formato"] : "yyyy-MM-dd HH:mm"
    );
  } catch (err) {
    // console.log(err);
    return null;
  }
};

const formatos = [
  "yyyy-MM-dd", // Formato ISO
  "dd/MM/yyyy", // Formato común
  "MM/dd/yyyy", // Formato EE.UU.
  "dd-MM-yyyy", // Formato con guiones
  "yyyy/MM/dd", // Otra variación ISO
];

export function parseISOString(s) {
  try {
    //  console.log(s, isValid(new Date(s))); // Verifica si ya es una fecha válida

    if (isValid(new Date(s))) return toDate(new Date(s)); // Si es una fecha válida, la devuelve

    if (!s) return; // Maneja si no hay entrada

    // Intentar con varios formatos conocidos
    let parsedDate;
    for (const formato of formatos) {
      parsedDate = parse(s, formato, new Date());
      if (isValid(parsedDate)) {
        return parsedDate;
      }
    }

    // Si el formato es algo como "0000-00-00", devolver null
    if (s === "0000-00-00") return null;

    // Si ninguno de los formatos coincidió, intentar dividir y crear una fecha manualmente
    const b = s.split(/\D+/).filter((b) => b);
    const d = new Date(Date.UTC(b[0], --b[1], b[2], b[3], b[4], b[5], b[6]));
    return d;
  } catch (er) {
    console.log(er, s);
    return s; // Si hay error, devolver el string original o manejar el error como corresponda
  }
}

export const getAtributo = (atributo, data, cab) => {
  const value = data?.[cab.id_a + "_" + atributo];

  if (
    value !== null &&
    value !== undefined &&
    (typeof value !== "string" || value.trim() !== "")
  ) {
    return value;
  }
  if (
    data &&
    cab[atributo + "_alias"] &&
    cab[atributo + "_alias"].trim() !== ""
  )
    return data[cab[atributo + "_alias"]];
  return cab[atributo];
};

// export const selector = (state, key, indiceConf, indiceData) => {
//   const getConfByIndex = (c, i) => c[i];
//   let configuracionState = state;
//   indiceConf.forEach((i) => {
//     configuracionState = getConfByIndex(configuracionState.configuraciones, i);
//   });
//   const dataObjeto = configuracionState.datos[indiceData];
//   return dataObjeto[key];
// };

const descargarArchivo = async (ruta) => {
  try {
    const response = await axios.post(ruta, {}, { responseType: "blob" });
    const blob = new Blob([response.data], {
      type: response.headers["content-type"],
    });
    const fileName = response.headers["filename"] || "archivo.pdf";
    return { blob, fileName };
  } catch (error) {
    console.error("Error al descargar el archivo:", error);
    throw error;
  }
};

// Función para manejar el archivo descargado (abrirlo en una ventana o guardarlo)
const manejarArchivoDescargado = (
  archivo,
  fileName,
  accion = "guardar",
  response
) => {
  // Verificar si el archivo es un blob o HTML
  const tipoDeContenido = response.headers["content-type"];
  const esHTML = tipoDeContenido === "text/html";

  // Crear URL según el tipo de archivo
  const archivoUrl = esHTML
    ? ""
    : window.URL.createObjectURL(
        new Blob([archivo], { type: tipoDeContenido })
      );

  if (accion === "abrir") {
    const windowWidth =
      response.headers["x-display-nueva-ventana-width"] ?? 800;
    const windowHeight =
      response.headers["x-display-nueva-ventana-height"] ?? 600;

    // Abrir en una nueva ventana
    const w = window.open(
      archivoUrl,
      "_blank",
      `width=${windowWidth},height=${windowHeight}`
    );
    if (esHTML) {
      w.document.write(archivo);
    }
  } else if (accion === "guardar") {
    // Crear un enlace de descarga programático para guardar el archivo
    const link = document.createElement("a");
    link.href = archivoUrl;
    link.setAttribute("download", fileName);
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  }

  // Liberar la URL si es un blob (para no causar fugas de memoria)
  if (!esHTML) window.URL.revokeObjectURL(archivoUrl);
};

export function getUserFile() {
  return new Promise((resolve, reject) => {
    const imginput = document.createElement("input");
    imginput.setAttribute("type", "file");
    imginput.setAttribute("accept", "image/*");

    imginput.onchange = () => {
      const file = imginput.files[0];
      if (file) {
        resolve(file);
      } else {
        reject(new Error("No file selected"));
      }
    };

    imginput.click();
  });
}

export async function uploadFileToS3(file, id_a) {
  try {
    document.body.style.cursor = "wait";
    const formData = new FormData();
    formData.append("archivo", file);
    formData.append("id_a", id_a);

    const res = await axios.post(farmageo_api + "/uploadFileToS3", formData);
    document.body.style.cursor = "default";
    return res.data;
  } catch (err) {
    console.log("uploadFileToS3: ", err);
    document.body.style.cursor = "default";
    throw err;
  }
}
