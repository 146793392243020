import React, { useEffect, useContext } from "react";
import { HashRouter, Route, Switch } from "react-router-dom";
import AlertasContext from "./views/Pantalla/context/AlertaContext";

// import { renderRoutes } from 'react-router-config';

import { AuthProvider } from "./views/Pages/AuthContext/AuthContext";
import ProtectedRoute from "./views/Pages/Login/ProtectedRoute";

import store from "./redux/store/index";
import { LOGOUT } from "./redux/actions/authActions";
import { ALERT } from "./redux/actions/alertActions";
import axios from "axios";
import PublicLayout from "./containers/DefaultLayout/PublicLayout";
import { LocalCarrusel } from "./views/Pantalla/Listado/components/Carrusel";

let activeRequests = 0;

const onRequestStart = () => {
  activeRequests += 1;
};

const onRequestEnd = () => {
  activeRequests -= 1;
  if (activeRequests === 0) {
    document.dispatchEvent(new Event('allRequestsComplete'));
  }
};

axios.interceptors.request.use((request) => {
  request.headers.authorization = `Bearer ${window.localStorage.getItem(
    "token"
  )}`;
  onRequestStart();
  return request;
});

axios.interceptors.response.use(
  (res) => {
    onRequestEnd();
    return res;
  },
  (err) => {
    onRequestEnd();
    switch (err.response.status) {
      case 409:
        return store.dispatch(
          ALERT(
            "Hubo un Problema con su solicitud",
            err.response.data.error.message,
            "error",
            "OK"
          )
        );
      case 401:
        // alert("Denegado: No tiene permiso para realizar esta accion");
        store.dispatch(
          ALERT(
            "ACCESO DENEGADO",
            err.response.data.error.message,
            "error",
            "OK"
          ).finally(() => {
            // window.location = process.env.PUBLIC_URL;
          })
        );

        break;
      case 440:
        //alert("Su sesion ha expirado, debe loguearse de nuevo");
        store.dispatch(
          ALERT(
            "SESION EXPIRADA",
            "Su sesion ha expirado debe loguearse nuevamente",
            "info",
            "OK"
          ).finally(() => {
            store.dispatch(LOGOUT());
            // window.location.replace(`${process.env.PUBLIC_URL}/#/login`);
          })
        );
        break;
      case 404:
        console.log("404");

        // console.log("404");
        // const newPath = `${process.env.PUBLIC_URL}/#/404`;
        // window.history.pushState({ path: newPath }, "", newPath);
        return err

      case 500:
        return store.dispatch(
          ALERT(
            "Ha Ocurrido un Error",
            "consulte con el administrador",
            "error",
            "Volver a Log In"
          ).finally(() => {
            store.dispatch(LOGOUT());
            window.location.replace(`${process.env.PUBLIC_URL}/#/login`);
          })
        );

      // return window.location.replace(`${process.env.PUBLIC_URL}/#/500`);
      default:
        break;
    }

    return err.response;
  }
);

const loading = () => (
  <div className="animated fadeIn pt-3 text-center">Cargando...</div>
);
// Containers
const DefaultLayout = React.lazy(() => import("./containers/DefaultLayout"));

// Pages
const Login = React.lazy(() => import("./views/Pages/Login"));
const Register = React.lazy(() => import("./views/Pages/Register"));
const Page404 = React.lazy(() => import("./views/Pages/Page404"));
const Page500 = React.lazy(() => import("./views/Pages/Page500"));

function App() {
  const { addToastAlert } = useContext(AlertasContext);

  useEffect(() => {
    const conexionAlert = addToastAlert({
      message: "Conexion",
      type: "warning",
    });

    const handleOnline = () => {
      console.log("Conexión restablecida: ", Date.now());
      // Realizar acciones para manejar la conexión restablecida
      conexionAlert.toSuccess({
        message: "La conexion fue reestablecida!",
      });
    };

    const handleOffline = () => {
      console.warn("Conexión perdida", Date.now());
      // Realizar acciones para manejar la conexión perdida
      conexionAlert.toError({ message: "Se ha perdido la conexion", timer: 0 });
    };

    window.addEventListener("online", handleOnline);
    window.addEventListener("offline", handleOffline);

    return () => {
      window.removeEventListener("online", handleOnline);
      window.removeEventListener("offline", handleOffline);
    };
  }, [addToastAlert]);
  return (
    <HashRouter>
      <React.Suspense fallback={loading()}>
        <Switch>
          <Route
            index
            exact
            path="/login"
            name="Entrar al sistema"
            render={(props) => <Login {...props} />}
          />
          <Route
            exact
            path="/register"
            name="Register Page"
            render={(props) => <Register {...props} />}
          />
          <Route
            exact
            path="/public/:pantalla"
            name="Public"
            render={(props) => <PublicLayout {...props} />}
          />
          <Route
            exact
            path="/viendoimagenes"
            name="Carrusel"
            render={(props) => <LocalCarrusel {...props} />}
          />
          <AuthProvider>
            <Route
              exact
              path="/404"
              name="Page 404"
              render={(props) => <Page404 {...props} />}
            />
            <Route
              exact
              path="/500"
              name="Page 500"
              render={(props) => <Page500 {...props} />}
            />
            <ProtectedRoute
              path="/"
              name="Home"
              Component={DefaultLayout}
              // render={(props) => <DefaultLayout {...props} />}
            />
          </AuthProvider>
        </Switch>
      </React.Suspense>
    </HashRouter>
  );
}

export default App;
