import React, { useState, useContext, useEffect, useRef } from "react";
import FuncionesContext, {
  getUserFile,
  uploadFileToS3,
} from "../context/FuncionesContext";
import { Editor } from "primereact/editor";
import Guardar from "../ABM/componentes/botonera/Guardar";
import Cancelar from "../ABM/componentes/botonera/Cancelar";
import { ReadOnlyTextArea } from "./EditorTextArea";
import { useRefDataStore } from "../../..";

import { v4 as uuidv4 } from "uuid";
import Quill from "quill";
const ImageBlot = Quill.import("formats/image");

class CustomImageBlot extends ImageBlot {
  static create(value) {
    // value es un objeto con atributos, por ejemplo: { src, dataId, customAttr }
    const container = document.createElement("span");
    container.classList.add("custom-image-container");
    // Hacemos que el contenedor no sea editable
    container.setAttribute("contenteditable", "false");
    // Posicionamiento para que el resizer se ubique correctamente
    container.style.position = "relative";
    container.style.display = "inline-block";

    // Crear el elemento <img>
    const img = document.createElement("img");
    img.setAttribute("src", typeof value === "object" ? value.src : value);
    // Por defecto, podemos darle un ancho/alto inicial
    img.style.width = "auto";
    img.style.height = "100px";
    // Si se pasan atributos personalizados, los asignamos
    if (typeof value === "object") {
      if (value.dataId) {
        img.setAttribute("data-id", value.dataId);
        img.setAttribute("id", value.dataId);
        img.style = value.style;
        img.setAttribute("height", value.height); // lo manejo por style mejor
        img.setAttribute("width", value.width);
      }
      if (value.customAttr) {
        img.setAttribute("custom-attr", value.customAttr);
      }
    }
    

    container.appendChild(img);

    // Crear el "handle" para redimensionar
    const resizer = document.createElement("div");
    resizer.classList.add("custom-image-resizer");
    // Estilos para que se vea como un pequeño cuadradito en la esquina inferior derecha
    resizer.style.width = "10px";
    resizer.style.height = "10px";
    resizer.style.background = "gray";
    resizer.style.position = "absolute";
    resizer.style.bottom = "0";
    resizer.style.right = "0";
    resizer.style.cursor = "nwse-resize";
    resizer.style.zIndex = "10";
    resizer.style.display = "none";

    container.appendChild(resizer);

    const mouseOverHandler = () => (resizer.style.display = "block");
    const mouseLeaveHandler = () => (resizer.style.display = "none");

    img.addEventListener("mouseenter", mouseOverHandler);
    resizer.addEventListener("mouseenter", mouseOverHandler);
    img.addEventListener("mouseleave", mouseLeaveHandler);
    resizer.addEventListener("mouseleave", mouseLeaveHandler);

    // Agregar funcionalidad de resize con el mouse mediante eventos
    let startX, startY, startWidth, startHeight;

    const mouseDownHandler = function (e) {
      e.preventDefault();
      // Guardamos las coordenadas iniciales y dimensiones actuales de la imagen
      startX = e.clientX;
      startY = e.clientY;
      startWidth = img.offsetWidth;
      startHeight = img.offsetHeight;
      document.addEventListener("mousemove", mouseMoveHandler);
      document.addEventListener("mouseup", mouseUpHandler);
    };

    const mouseMoveHandler = function (e) {
      e.preventDefault();
      // Calculamos las nuevas dimensiones
      const newWidth = startWidth + (e.clientX - startX);
      const newHeight = startHeight + (e.clientY - startY);
      // Asignamos las nuevas dimensiones a la imagen
      img.style.width = newWidth + "px";
      img.style.height = newHeight + "px";
      img.setAttribute("width", newWidth);
      img.setAttribute("height", newHeight);
    };

    const mouseUpHandler = function (e) {
      document.removeEventListener("mousemove", mouseMoveHandler);
      document.removeEventListener("mouseup", mouseUpHandler);
      document.removeEventListener("mouseenter", mouseOverHandler);
      document.removeEventListener("mouseleave", mouseLeaveHandler);

      // informar de cambios
      const quillInstance = Quill.find(container.closest(".ql-container"));

      if (quillInstance) {
        quillInstance.root.dispatchEvent(
          new Event("resized", { bubbles: true }) // Dispara evento de cambio
        );
      }
    };

    // Guardamos el handler en el contenedor para poder limpiar (opcional)
    container.__mouseDownHandler = mouseDownHandler;
    resizer.addEventListener("mousedown", mouseDownHandler);
    container.__eventHandlers = {
      mouseOverHandler,
      mouseLeaveHandler,
      mouseDownHandler,
    };

    // También puedes guardar el resizer en el contenedor para facilitar la limpieza
    container.__resizer = resizer;

    return container;
  }

  static value(node) {
    // Extraemos la información para guardarla en el delta de Quill

    return {
      src: node.getAttribute("src"),
      dataId: node.getAttribute("data-id"),
      id: node.getAttribute("id"),
      style: node.style,
      height: node.getAttribute("height"),
      width: node.getAttribute("width"),
    };
  }

  format(name, value) {
    if (name === "data-id" || name === "id") {
      if (value) {
        this.domNode.setAttribute(name, value);
      } else {
        this.domNode.removeAttribute(name);
      }
    } else {
      super.format(name, value);
    }
  }

  detach() {
    if (this.domNode && this.domNode.__eventHandlers) {
      const { mouseOverHandler, mouseLeaveHandler, mouseDownHandler } =
        this.domNode.__eventHandlers;
      const img = this.domNode.querySelector("img");
      const resizer = this.domNode.querySelector(".custom-image-resizer");

      if (img) {
        img.removeEventListener("mouseenter", mouseOverHandler);
        img.removeEventListener("mouseleave", mouseLeaveHandler);
      }
      if (resizer) {
        resizer.removeEventListener("mouseenter", mouseOverHandler);
        resizer.removeEventListener("mouseleave", mouseLeaveHandler);
        resizer.removeEventListener("mousedown", mouseDownHandler);
      }
    }
    super.detach();
  }
}

CustomImageBlot.blotName = "customImage"; // Nombre interno del blot
CustomImageBlot.tagName = "img"; // Etiqueta que representará este blot

// Registrar el custom blot en Quill
Quill.register(CustomImageBlot);

// EL COMPONENTE REQUIERE QUILL 1.3.7 y PRIMEREACT@5

const NoteTaker = (props) => {
  const { data, cab, campokey, context, id_elemento, setValor, valor } = props;
  const { superSubmit, validarRegex } = useContext(FuncionesContext);

  const { opciones } = useContext(context);

  const [v, setStore] = useRefDataStore((s) => s[data._key + campokey]);
  const [update_id] = useRefDataStore(
    (s) => s[data._key + cab.update_id_alias]
  );

  const [value, setValue] = useState(v ?? valor);
  const [lastValue, setLastvalue] = useState(v ?? valor);
  const [isFocused, setIsFocused] = useState(false);
  const [guardado, setGuardado] = useState(true);
  const [updateId, setUpdateId] = useState(
    update_id ?? data[cab.update_id_alias]
  );

  const miComponenteRef = useRef(null);

  const nombre = (() => {
    if (cab.nombre_alias) {
      return data[cab.nombre_alias];
    }
    return cab.nombre;
  })();

  const disabled = data[cab.id_a + "_disabled"]
    ? data[cab.id_a + "_disabled"] === "s"
    : cab.disabled === "s";

  let classNames = data[cab.id_a + "_className"] ?? cab.className;

  const handleCancelar = () => {
    setValue(v);
    setLastvalue(v);
    setIsFocused(false);
    setGuardado(true);
  };

  class handleGuardar {
    static update = async (e) => {
      const valor = value;
    
      if (valor === null || lastValue?.toString() === valor.toString().trim())
        return;

      if (!validarRegex(valor, cab.validacion_regex, cab)) {
        setValue(v);
        return;
      }

      const { id_a } = cab;
      setIsFocused(false);

      superSubmit({
        valor,
        id_a,
        update_id: updateId,
        handleCancelar,
        cab,
        data,
      })
        .then((result) => {
          setLastvalue(() => valor);
          setStore({ [data._key + campokey]: valor });
          if (!updateId) {
            setStore({
              [data._key + cab.update_id_alias]: result.data.registro.id,
            });
            setUpdateId(result.data.registro.id);
          }

          setGuardado(true);
          return result;
        })
        .catch((err) => {
          console.log("Cancelado ", err);
        });
    };

    static abm = async (e) => {
      return e;
    };
  }

  class handleChange {
    static abm = (e) => {
      setValue(e.htmlValue);
      setValor(e.htmlValue);
    };
    static update = (e) => {
   
      setGuardado(e.htmlValue === lastValue);
      setValue(e.htmlValue);
    };
  }

  (() => {
    if (updateId) {
      classNames = `${classNames} 
        ${data[cab.id_a + "_update_className"] ?? cab.update_className}`;
      return;
    }
    classNames = classNames + " sinUpdateId";
    return;
  })();

  useEffect(() => {
    const handleClick = (event) => {
      // Verificar si el clic ocurrió dentro del componente
      if (
        miComponenteRef &&
        miComponenteRef.current &&
        miComponenteRef.current.contains(event.target)
      ) {
        if (opciones.tipo.id === 9) return;
        setIsFocused(true);
      } else {
        setIsFocused(false);
      }
    };

    // Agregar event listener para clics en cualquier parte del documento
    document.addEventListener("click", handleClick);

    // Limpiar el event listener al desmontar el componente
    return () => {
      document.removeEventListener("click", handleClick);
    };
  }, []);

  const insertImage = (url, quill) => {
    //const url="https://s1.elespanol.com/2021/10/18/ciencia/nutricion/620450765_212061138_1706x960.jpg";

    let range = quill.getSelection();
    if (!range) {
      range = { index: quill.getLength(), length: 0 };
    }

    const imageId = `img${uuidv4()}`;

    // Insertar la imagen en Quill
    quill.insertEmbed(range.index, "customImage", {
      src: url,
      dataId: imageId,
      width: "auto",
      height: "100px",
    });

    quill.setSelection(range.index + 1, 0);
  };

  async function uploadImage(file) {
    const url = await uploadFileToS3(file, cab.id_a);
    return url;
  }

  async function imageHandler(p) {
    const quill = this.quill;

    // get document
    const file = await getUserFile();

    const url = await uploadImage(file);

    //add img with src
    insertImage(url, quill);
  }

  useEffect(() => {
    const editor = document.getElementById(id_elemento);
    if (editor) {
      editor.addEventListener("resized", () => {
        const p = editor.querySelector(".ql-editor");

        const newContent = `${p.innerHTML.toString()}`;

        handleChange.update({ htmlValue: newContent });
      });
    }
  }, [handleChange, id_elemento]);

  // console.log(cab.id_a, value);
  if (disabled) {
    return <ReadOnlyTextArea {...props} />;
  }

  return (
    <div
      style={{
        width: "100%",
        flexWrap: "wrap",
        border: guardado ? "none" : "2px solid red",
      }}
      id={id_elemento}
      className={`tarjeta_grid_item_label_item  d-flex flex-column align-items-start ${
        cab.habilita_insertar_imagenes === "s" ? "" : "ql-image-none"
      }`}
      ref={miComponenteRef}
    >
      {nombre ? (
        <div
          className={
            opciones.tipo.id === 9 ? "labelNoteTakerABM" : "labelNoteTaker"
          }
        >
          {nombre}:
        </div>
      ) : (
        <></>
      )}

      <Editor
        disabled={disabled}
        className={
          disabled
            ? classNames + " textArea_disabled"
            : classNames + " inputTextArea"
        }
        id={id_elemento + "_editor"}
        value={value}
        onTextChange={
          opciones.tipo.id === 9 ? handleChange.abm : handleChange.update
        }
        pt={{
          root: {
            style: {
              width: cab.width_input ?? "100%",
            },
          },
        }}
        readOnly={disabled}
        // onBlur={handleGuardar}
        modules={{
          toolbar: {
            container: [
              [{ size: ["small", false, "large", "huge"] }],
              [{ font: [] }],
              [{ color: [] }, { background: [] }],
              [{ align: [] }],
              ["blockquote", "code-block"],
              ["bold", "italic", "underline", "strike"],
              [{ list: "ordered" }, { list: "bullet" }],
              ["image"],
            ],
            handlers: { image: imageHandler },
          },
        }}
      />
      {isFocused ? (
        <div
          style={{ width: "100%", margin: "0.2rem" }}
          className="btns-noteTaker"
        >
          <Guardar
            handleSubmit={
              opciones.tipo.id === 9 ? handleGuardar.abm : handleGuardar.update
            }
            texto_confirmar="Guardar"
            size="small"
            buttonId={id_elemento + "_submit"}
            submitOnEnter={false}
          />
          <Cancelar size="small" handleCancelar={handleCancelar} />
        </div>
      ) : (
        <></>
      )}
    </div>
  );
};

export default NoteTaker;

export const ABMNoteTaker = (props) => {
  return <div></div>;
};
