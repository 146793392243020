import React, { useRef } from "react";
import { getAtributo } from "../../../context/FuncionesContext";

const Default = ({
  data,
  cab,
  hijos,
  campokey,
  indiceData,
  id_elemento,
  number,
}) => {
  const condcionBadge = cab.id_a === "T_COL_ID_TRANSFER" && "0.9em";
  const ref = useRef(null);
  if (!data[campokey] && data[campokey] != 0) return <></>;
  const align = getAtributo("align", data, cab);

  if (number) {
    return (
      <div
        id={"DEFAULT_COLUMNA_SIMPLE_BASE_" + cab.id_a + "_" + indiceData}
        style={{
          display: "flex",
          justifyContent: align ?? "left",
          marginTop: "auto",
          marginBottom: "auto",
        }}
      >
        <div
          id={id_elemento}
          className={data[cab.id_a + "_className"]}
          style={{
            textAlign: align ?? "left",
            fontSize: condcionBadge,
            border: cab.border,
            borderTop: cab.borderTop,
          }}
          ref={ref}
        >
          {data[campokey]
            .toFixed(parseInt(data[cab.id_a + "_round"] ?? cab.round))}
          {hijos}
        </div>
      </div>
    );
  }

  return (
    <div
      id={"DEFAULT_COLUMNA_SIMPLE_BASE_" + cab.id_a + "_" + indiceData}
      style={{
        display: "flex",
        justifyContent: align ?? "left",
        marginTop: "auto",
        marginBottom: "auto",
      }}
    >
      <div
        id={id_elemento}
        className={data[cab.id_a + "_className"]}
        style={{
          textAlign: align ?? "left",
          fontSize: condcionBadge,
          border: cab.border,
          borderTop: cab.borderTop,
        }}
        ref={ref}
      >
        {data[campokey]}
        {hijos}
      </div>
    </div>
  );
};

export default Default;

export const Code = ({
  data,
  cab,
  hijos,
  campokey,
  indiceData,
  id_elemento,
}) => {
  return (
    <code
      id={id_elemento}
      className={data[cab.id_a + "_className"]}
      style={{ textAlign: cab.align ?? "left", color: "red" }}
    >
      {data[campokey]}
      {hijos}
    </code>
  );
};
