import React, { createContext, useReducer } from "react";
import orixiaTheme from "./themes/orixiaTheme/orixiaTheme";
import amrTheme from "./themes/amrTheme/amrTheme";
import cfsfTheme from "./themes/cfsfTheme/cfsfTheme";

export const ThemeContext = createContext();

export const initialState = {
  logo: "",
};

export const ThemeReducer = (state, action) => {
  switch (action.type) {
    case "SET_LOGO":
      return {
        ...state,
        logo: action.payload.logo,
        bgImage: action.payload.bgImage,
        logoWhite: action.payload.logoWhite,
        logoWhiteColegio: action.payload.logoWhiteColegio,
      };
    default:
      return state;
  }
};

const ThemeG = (props) => {
  const theme = process.env.REACT_APP_THEME;

  const [state, dispatch] = useReducer(ThemeReducer, initialState);

  let ThemeComponent;

  switch (theme) {
    case "amr":
      ThemeComponent = amrTheme;
      break;
    case "orixia":
      ThemeComponent = orixiaTheme;
      break;
    case "cfsf":
      ThemeComponent = cfsfTheme;
      break;
    default:
      break;
  }
  const loading = () => (
    <div className="animated fadeIn pt-3 text-center">Cargando Estilos...</div>
  );

  return (
    <ThemeContext.Provider
      value={{
        ...state,
        theme,
        dispatch,
      }}
    >
      <React.Suspense fallback={loading()}>
        <ThemeComponent>{props.children}</ThemeComponent>
      </React.Suspense>
    </ThemeContext.Provider>
  );
};

export default ThemeG;
